import React, { ReactElement } from "react";
import { SidebarButton } from "./SidebarButton";
import phoneIcon from "../resources/phoneIcon.svg";
import avatarPlaceholderIcon from "../resources/advisor-avatar.svg";
import "../styles/ServiceAdvisorView.scss";

const ServiceAdvisorView = (props: {
  name: string;
  number: string;
  chatLink: string;
  profileImageUrl: string;
}): ReactElement => {
 

  return (
    <div className="ServiceAdvisorView">
      <div className="advisorHeader">YOUR SERVICE ADVISOR:</div>
      <div className="advisorContainer">
        <img
          className="advisorAvatar"
          src={avatarPlaceholderIcon}
          alt="Advisor Avatar"
        />
        <div className="flexColumn">
          <div className="advisorName">{props.name}</div>
        </div>
      </div>
    </div>
  );
};

export default ServiceAdvisorView;
